import React ,{useRef} from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import Slider from "react-slick";

const Team: React.FC = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
              },
          ]
    };
    const slider = useRef<Slider | null>(null);
    const next = () => {
        if(slider.current){
            slider.current.slickNext();
        }
      }
      const previous = () => {
        if(slider.current){
            slider.current.slickPrev();
        }
      }
    return (
        <Box>
            <Container  className=" wow fadeInDown"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                <div className="top">
                <h3>Meet the founder</h3>
                </div>

                <div className="teamOuter">
                            <img src={require("../../assets/founder.jpg")} alt="" />
                            <h4>Gustavo</h4>
                            <h6>Chief Executive Officer</h6>
                            <a href="https://www.linkedin.com/in/gustavo-novita-446b2220/" target="_blank"><button className="outline">Linked<i className="fab fa-linkedin"></i></button></a>
                        </div>
                {/* <div className="sliderOuter">
                        <i className="fa fa-chevron-left control" onClick={previous}></i>
                        <i className="fa fa-chevron-right control" onClick={next}></i>
                    <Slider {...settings} ref={slider}>
                        <div className="teamOuter">
                            <img src={require("../../assets/founder.jpg")} alt="" />
                            <h4>Gustavo</h4>
                            <h6>Chief Executive Officer</h6>
                            <a href=""><button className="outline">Linked<i className="fab fa-linkedin"></i></button></a>
                        </div>
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Max Mustermann</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div> 
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Max Mustermann</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div>
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Gustavo</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div>
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Max Mustermann</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div>
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Gustavo</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div>
                        <div className="teamOuter">
                            <img src={require("../../assets/grey.png")} alt="" />
                            <h4>Max Mustermann</h4>
                            <h6>Chief Executive Officer</h6>
                            <button className="outline">Linked<i className="fab fa-linkedin"></i></button>
                        </div>
                    </Slider>
                </div> */}
                
            </Container>
        </Box>
    );
};
const Box = styled.section`
margin-bottom:60px;
    .top{
        text-align:center;
        margin-bottom:40px;
        h3{
            font-weight:700;
            margin-bottom:26px
        }
    }
    .sliderOuter{
        position:relative;
        .control{
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            &.fa-chevron-left{
                left:-10px;
                cursor:pointer;
            }
            &.fa-chevron-right{
                right:-10px;
                cursor:pointer;
            }
        }
    }
    
    .teamOuter{
        text-align:center;
        img{
            width:100px;
            margin-bottom:20px;
            border-radius:50%;
        }
        h4{
            font-weight:600;
            font-size:18px;
        }
        .outline{
            background:transparent;
            border:2px solid #0965c1;
            color:#0965c1;
            margin-top:15px;
            padding: 8px 28px;
            i{
                margin-left:3px;
            }
        }
    }
`;

export default Team;
