import React ,{useRef} from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import Slider from "react-slick";

const Donate: React.FC = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
              },
          ]
    };
    const slider = useRef<Slider | null>(null);
    const next = () => {
        if(slider.current){
            slider.current.slickNext();
        }
      }
      const previous = () => {
        if(slider.current){
            slider.current.slickPrev();
        }
      }
    return (
        <Box>
            <Container  className=" wow fadeInDown"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                <div className="top">
                    <h3>
                        We donate to causes{" "}
                        <span className="text-greenish">your care</span> about
                    </h3>
                    <div className="controls">
                        <i className="fa fa-chevron-left" onClick={previous}></i>
                        <i className="fa fa-chevron-right" onClick={next}></i>
                    </div>
                </div>
                <div className="donateOuterMain">
                    <Slider {...settings} ref={slider}>
                        <div className="donateOuter">
                            <img src={require("../../assets/1.png")} alt="" />
                            <div>Reforestation</div>
                        </div>
                        <div className="donateOuter">
                            <img src={require("../../assets/2.png")} alt="" />
                            <div>Clean Ocean</div>
                        </div>
                        <div className="donateOuter">
                            <img src={require("../../assets/3.png")} alt="" />
                            <div>Carbon Free</div>
                        </div>
                        <div className="donateOuter">
                            <img src={require("../../assets/1.png")} alt="" />
                            <div>Reforestation</div>
                        </div>
                        <div className="donateOuter">
                            <img src={require("../../assets/2.png")} alt="" />
                            <div>Clean Ocean</div>
                        </div>
                        <div className="donateOuter">
                            <img src={require("../../assets/3.png")} alt="" />
                            <div>Carbon Free</div>
                        </div>
                    </Slider>
                </div>
            </Container>
        </Box>
    );
};
const Box = styled.section`
    .top {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h3 {
            font-weight: 700;
            max-width: 300px;
        }
        .controls {
            margin-bottom: 3px;
            i {
                cursor: pointer;
            }
            .fa-chevron-left {
                margin-right: 16px;
            }
        }
    }
    .row {
        h5 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
    .donateOuterMain{
        margin:0 -20px;
    }
    .donateOuter {
        padding: 0 20px;
        img {
            width: 100%;
            border-radius: 8px;
        }
        div {
            color: #03242b;
            font-weight: bold;
            margin-top: 12px;
            font-size: 18px;
            padding-left: 6px;
        }
    }
`;

export default Donate;
