import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Whitepaper: React.FC = () => {
    return (
        <Box id="whitepaper">
            <Container>
                <div className="top wow fadeInDown"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                <h3>THE CLIMATE ECOSYSTEM Whitepaper</h3>
                <p>Creating value for your donation through our DeFi platform</p>
                </div>
                <Row >
                    <Col  md={12} lg={6} className=" wow fadeInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                        <h5>Climate Change</h5>
                        <p>
                        Climate change refers to long-term shifts in temperatures and weather patterns. These shifts may be natural, but since the 1800s, human activities have been the main drive of climate change, primarily due to the burning of fossil fuels (coal, oil, gas). This burning generates greenhouse gas emissions that are like a blanket wrapped around the Earth, trapping the sun’s heat and raising temperatures. (Source: the United Nations)
                        </p>
                    </Col>
                    <Col  md={12} lg={6} className=" wow fadeInRight"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                        <h5>DeFi</h5>
                        <p>
                        Decentralized Finance (DeFi) is an emerging financial technology based on secure distributed ledgers similar to those used by cryptocurrencies. The system removes the central banks and institutions have on money, financial products and services. (Source: Investopedia)
                        </p>
                    </Col>
                </Row>
                <Row className="myrow2">
                    <Col  md={12} lg={6} className=" wow fadeInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.6s">
                        <h5>NFT</h5>
                        <p>
                        Non-Fungible Tokens (NFTs) are cryptographic assets on blockchain with unique identification codes and metadata that distinguish them from each other. Unlike cryptocurrencies, they can not be traded or exchanged at equivalency. (Source: Investopedia)
                        </p>
                    </Col>
                    <Col  md={12} lg={6}  className=" wow fadeInRight"
        data-wow-duration="0.8s"
        data-wow-delay="0.5s">
                        <h5>PancakeSwap</h5>
                        <p>
                        PancakeSwap is a decentralized exchange (DEX), which operates on Binance Smart Chain (BSC). PancakeSwap offers faster and cheaper trading, where no centralized authority is involved. It uses liquidity pools to reward liquidity providers for contributing assets. The exchange also has a bridge that allows users to bring
non-native assets (non BEP-20 tokens).
                        </p>
                    </Col>
                </Row>
                <button  className=" wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s"><a href="https://docdro.id/mBZsJ3g" target="_blank">Whitepaper</a></button>
            </Container>
        </Box>
    );
};
const Box = styled.section`
    background-color: #2d3551;
    color: #fff;
    min-height: 100vh;
    .top{
        text-align:center;
        margin-bottom:70px;
        h3{
            font-weight:700;
            margin-bottom:26px
        }
    }
    .row{
        h5{
            font-size:20px;
            font-weight:600;
            margin-bottom:20px
        }
    }
    .myrow2{
        margin-top:40px;
    }
    button{
        margin:auto;
        margin-top:40px;
        display:block;
        a{
            color:#03242b;
            text-decoration:none;
        }
    }
    @media screen and (max-width:992px){
        .col-lg-6{
            margin-bottom:40px;
        }
        .myrow2 {
            margin-top: 0px;
        }
        button{
            margin-top:20px;
        }
    }
`;

export default Whitepaper;
