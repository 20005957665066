import React from 'react';
import logo from './logo.svg';
import Home from './views/Home'

function App() {
  return (
    <Home/>
  );
}

export default App;
