import React ,{useRef} from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Footer: React.FC = () => {

    return (
        <Box>
            <Container  className=" wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s">
                <Row >
                    <Col md={12} lg={4} className="col1">
                        <img src={require("../../assets/logofooter.png")} className="logo" alt="" />
                        <button className="bg-greenish text-white">
                           Donate
                        </button>
                        <div className="socialOuter">
                            <a href="https://t.me/Climate_Ecosystem" target="_blank"><i className="fas fa-paper-plane"></i></a>
                            <a href="https://twitter.com/climetoken" target="_blank"><i className="fab fa-twitter"></i></a>
                        </div>
                    </Col>
                    <Col md={12} lg={2} className="col2">
                       <a href="/">Start</a>
                       <a  href="#whitepaper">Whitepaper</a>
                       <a href="#roadmap">Roadmap</a>
                    </Col>

                    <Col md={12} lg={2} className="col3">
                       <a href="#climendcarbc">CLIME & CARBC</a>
                       <a href="https://www.cnbc.com/amp/2021/10/25/blackrock-ceo-larry-fink-next-1000-unicorns-will-be-in-climate-tech.html" target="_blank">News</a>
                       <a href="#">Exchange</a>
                       <a href="#">Farm</a>
                    </Col>

                    <Col md={12} lg={4} className="col4">
                       <h2>Creating value for your donation through our DeFi platform</h2>
                       <p style={{color:"#62687d"}}>THE CLIMATE ECOSYSTEM CORP. is a company established in Panama by all legal entities and for all purposes.</p>
                       <p>Copyright 2022 by THE CLIMATE ECOSYSTEM CORP.</p>
                    </Col>
                </Row>
            </Container>
        </Box>
    );
};
const Box = styled.section`
background-color: #2d3551;
padding: 50px 0;
.col1{
    .logo{
        width:80%;
        max-width:260px;
        display:block;
    }
    button{
        margin:26px 0;
    }
    .socialOuter{
        a{
            color:#fff;
            i{
                margin-right:20px;
            }
        }
    }
}


.col2, .col3{
    a{
        display:block;
        text-decoration:none;
        color:#fff;
        margin-bottom:16px;
        font-weight:500;
    }
}


.col4{
    color:#fff;
    font-size:15px;
    h2{
        color:#fff;
        font-weight:600;
        font-size:22px;
        margin-bottom:16px;
    }
    p{
        margin-bottom:12px;
    }
}

@media screen and (max-width:992px){
    .col1, .col2, .col3, .col4{
        padding:0 !important;
        margin-bottom:32px;
    }
}
    
    
`;

export default Footer;
