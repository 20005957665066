import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Roadmap: React.FC = () => {
    return (
        <Box id="roadmap">
            <Container>
                <div className="top wow fadeInDown"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.3s">
                    <h3>Roadmap</h3>
                </div>
                <div className="roadmapbxOuter">
                    <Row>
                        <Col md={0} lg={6}></Col>
                        <Col md={12} lg={6} className="rightcol wow fadeInRight"
                            data-wow-duration="0.8s"
                            data-wow-delay="0.3s">
                            <h5>1st quarter 2022</h5>
                            <ul>
                                <li>CLIME Pre-Sale</li>
                                <li>LCARBON Farming and Staking Platform ready</li>
                                <li>Climate Ecosysem DEX Ready</li>
                                <li>Coinmarketcap listing</li>
                                <li>Coingecko Listing</li>
                                <li>Pancake swap listing</li>
                                <li>
                                    Researching and Setting up Climate change
                                    institutions to receive the donations
                                </li>
                                <li>First Donation from Pre-Sale amount</li>
                                <li>LCARBON Farming and Staking Platform ready</li>
                            </ul>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} lg={6} className="leftcol wow fadeInLeft"
                            data-wow-duration="0.8s"
                            data-wow-delay="0.3s">
                            <h5>2nd quarter 2022</h5>
                            <ul>
                                <li>Monthly donation starts</li>
                                <li>First Tier CEX CLIME listing</li>
                                <li>
                                    Researching and Setting Climate change
                                    institutions to receive the donations
                                </li>
                                <li>First CLIME NFTs collection ready for sale</li>
                            </ul>
                        </Col>
                        <Col md={0} lg={6}></Col>
                    </Row>
                </div>
            </Container>
        </Box>
    );
};
const Box = styled.section`
    .top {
        text-align: center;
        margin-bottom: 70px;
        h3 {
            font-weight: 700;
            margin-bottom: 26px;
        }
    }
    
    .roadmapbxOuter{
        position:relative;
        &::before{
            height:calc(100% - 20px);
            width:2px;
            background:#03232b;
            content:"";
            position:absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
        }
        .row {
            h5 {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .leftcol {
                text-align: right;
                
                ul{
                    direction: rtl;
                    paddding-left:0;
                    padding-right:14px;
                }
                position:relative;
                &::before{
                    height:30px;
                    width:30px;
                    background:#03232b;
                    content:"";
                    position:absolute;
                    top:-2px;
                    right:0;
                    border:10px solid #fff;
                    border-radius:50%;
                    transform:translateX(50%);
                }
            }
            .rightcol{
                position:relative;
                margin-bottom:30px;
                &::before{
                    height:30px;
                    width:30px;
                    background:#03232b;
                    content:"";
                    position:absolute;
                    top:0px;
                    left:0 !important;
                    border:10px solid #fff;
                    border-radius:50%;
                    transform:translateX(-50%);
                }
            }
            ul{
                padding-left:14px;
            }
        }
    }

    @media screen and (max-width:992px){
        .roadmapbxOuter{
            padding-left:60px;
            &::before{
                left:34px;
                transform:translateX(0%);
            }
            h5{
                font-size:24px !important;
            }
            .row{
                width:100%;
            }
        }
        .leftcol {
            text-align: left !important;
            ul{
                direction: unset !important;
            }
            &::before{
                top:0px !important;
                left:0;
                transform:translateX(0%) !important;
            }
        }
        .rightcol{
            position:relative;
            margin-bottom:30px;
            &::before{
                top:0px;
                left:0 !important;
                transform:translateX(0%) !important;
            }
        }
        ul{
            padding-left:14px;
        }
    }



    @media screen and (max-width:500px){
        .roadmapbxOuter{
            padding-left:40px;
            &::before{
                left:24px;
                transform:translateX(0%);
            }
            h5{
                font-size:20px !important;
            }
            .row {
                .leftcol {
                    &::before{
                        left:-30px !important;
                    }
                }
                .rightcol{
                    margin-bottom:30px;
                    &::before{
                        left:-30px !important;
                    }
                }
            }

        }
       

    }

   
`;

export default Roadmap;
