import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Hero: React.FC = () => {
    return (
        <Box>
            <Container>
                <Row >
                    <Col md={12} lg={6} className="leftcol wow fadeInLeft"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.6s">
                            <div className="bluetop">
                                <div className="left"><i className="fas fa-exclamation-circle"></i></div>
                                <div className="center">CLIME Pre-Sale is coming soon, stay tuned to our social media and don't miss the chance to participate in our airdrop promotion.</div>
                                <div className="right">
                                    <a href="https://t.me/Climate_Ecosystem" target="_blank"><i className="fas fa-paper-plane"></i></a>
                                    <a href="https://twitter.com/climetoken" target="_blank"><i className="fab fa-twitter"></i></a>
                                </div>
                            </div>
                        <h1>Create <span className="text-greenish">real value for your donation</span> through our DeFi platform</h1>
                        <p>
                            Invest in combating climate change and get returns on your investements
                        </p>
                        <p className="text-greenish " style={{ fontWeight: 600 }}>
                            After CLIME pre-sale ends get ready to farm CLIME to receive our Carbon Credit token
                        </p>
                        <div className="btnOuter">
                            <button className="bg-greenish text-white">
                                Buy CLIME
                            </button>
                            <button >
                                Buy CARBC
                            </button>
                        </div>
                        {/* <p className="text-lightgray " style={{ fontWeight: 600 }}          >CLIME Pre-Sale is coming soon, stay tuned to our social media and don't miss the chance to participate in our airdrop promotion.</p> */}
                    </Col>
                    <Col md={12} lg={6} className="rightcol  wow fadeInRight"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.6s">
                        <img src={require("../../assets/hero.png")} alt="" />
                    </Col>
                </Row>
                <img src={require("../../assets/partners.png")} className="partners  wow fadeInDown"
                    data-wow-duration="0.8s"
                    data-wow-delay="1.2s" alt="" />

            </Container>
        </Box>
    );
};
const Box = styled.section`
    min-height: 100vh;
    padding-top:150px;
    .bluetop{
        display:flex;
        align-items:center;
        justify-content:space-between;
        background-color: #2569bb;
        color:#fff;
        border-radius:10px;
        padding:8px 12px;
        font-size:14px;
        line-height:18px;
        margin-bottom:20px;
        a{
            color:#fff;
        }
        .left{
            i{
                margin-right:14px;
            }
        }
        .right{
            display:flex;
            a{
                margin-left:14px;
            }
        }
    }
    .row{
        h1{
            font-weight:600;
            margin-bottom:20px
        }
        .leftcol{
            .btnOuter{
                display:flex;
                margin-bottom:20px;
                button:first-child{
                    margin-right:20px;
                }
            }
        }
        .rightcol{
            img{
                width:100%;
                max-width:400px;
                display:block;
                margin-left:auto;
            }
        }
    }
    .partners{
        width:100%;
        margin-top:80px;
    }
    @media screen and (max-width:992px){
        padding-top:12vh;
        .rightcol{
            img{
                margin-right:auto;
                margin-top:70px;
            }
        }
    }

`;

export default Hero;
