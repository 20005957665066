import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Info: React.FC = () => {
    return (
        <Box>
            <Container>
                <Row>
                    <Col md={12} lg={6} className=" wow fadeInLeft"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.3s">
                        <h5>What is THE CLIMATE ECOSYSTEM?</h5>
                        <p>
                            THE CLIMATE ECOSYSTEM is a global team of
                            like-minded people from the UK, EU, Brazil, Russia
                            and Panama. We are united by one big concern:
                            climate change. Our goal is to address this
                            challenge as efficiently - and quickly - as
                            possible.{" "}
                        </p>

                        <p>
                            This is why we created this project: to help
                            organizations from all over the world in their fight
                            against our mutual problem.{" "}
                        </p>

                        <p>
                            THE CLIMATE ECOSYSTEM will facilitate donations and
                            their distribution to organizations fighting
                            consequences of climate change or working to prevent
                            damaging effects of it in the future.{" "}
                        </p>

                        <p>
                            The core part of our work is to perform a thorough
                            research and find companies eligible for donations.
                            We already have several partners on board and we
                            will add more organizations to the pool.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className=" wow fadeInRight"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.3s">
                        <h5>Mission</h5>
                        <p>
                            Climate change is a fact, and many of us realize its
                            consequences. We see that extreme events are
                            happening more often and in the less common areas,
                            causing damage, deaths and impacting our lifestyles
                            overall.
                        </p>

                        <p>
                            We are all responsible - and capable - of reversing
                            this scenario. We must learn and adopt sustainable
                            practices in our daily lives, such as using clean
                            energy, consuming products and services produced by
                            eco-conscious companies. This is, of course, a very
                            minimum of actionable ideas.
                        </p>

                        <p>
                            That’s why we’re developing THE CLIMATE ECOSYSTEM
                            which will facilitate mutual efforts in fighting
                            against global challenges.
                        </p>
                    </Col>
                </Row>
                <Row className="myrow2" id="climendcarbc">
                    <Col md={12} lg={6}  id="climetoken" className=" wow fadeInLeft"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.3s">
                        <h5><img src={require("../../assets/clime.png")} alt="" />CLIME</h5>
                        <p>
                            <b>Tokenomics:</b>
                            Total Supply: 10.000.000.000
                        </p>

                        <p style={{ fontStyle: "italic" }}>
                            Pre-sale information and token distribution will be
                            available soon. As soon as pre-sale ends, investors
                            will be able to Farm and Stake CLIME to receive
                            CARBC (Carbon Credit) token
                        </p>

                        <p>
                            <b>
                                Transaction fees: there will be no transaction
                                fees for stake, unstake and farming on our CARBC
                                farm platform.
                            </b>
                        </p>

                        <p>
                            All other transaction will have 10.00% fee:
                            <br />
                            5.00% send to holders; <br />
                            3.00% donation;
                            <br />
                            2.00% liquidity.
                        </p>

                        <p>
                            This tokenomics will ensure that each CLIME
                            transaction has a profit for holders, as well as
                            value generation for donation.
                        </p>

                        <p>
                            CLIME holders will have the right to vote for the
                            organization that will receive the donation.
                        </p>
                        <p>
                            <p>
                                {" "}
                                Benefits of buying and holding the CLIME token:
                            </p>

                            <ul>
                                <li>
                                    Taking an active part in the global
                                    challenge - fighting and preventing the
                                    consequences of the world's biggest concern,
                                    which is climate change;{" "}
                                </li>

                                <li>
                                    Increasing the value of the CLIME token -
                                    with many governments, organizations,
                                    companies and individuals concerned about
                                    climate change, our project will inevitably
                                    see the increase in traffic and in value;
                                </li>

                                <li>
                                    Staking and Farming the CLIME token - by
                                    doing so investors will receive exclusive
                                    rewards in CARBC (our CARBON CREDIT token,
                                    see information below);{" "}
                                </li>

                                <li>
                                    Purchasing NFTs - the CLIME token will be
                                    the official currency to do so (when
                                    available);
                                </li>

                                <li>
                                    Earning significant returns on investments
                                    while supporting the most important cause.
                                </li>
                            </ul>
                        </p>
                        <button className="bg-greenish text-white">
                            Buy CLIME
                        </button>
                    </Col>
                    <Col md={12} lg={6} id="carbctoken" className=" wow fadeInRight"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.3s">
                        <h5><img src={require("../../assets/carbc.png")} alt="" /> CARBC <span>(Carbon Credit)</span></h5>
                        <p style={{ fontStyle: "italic" }}>
                            This is our farm token, needed to receive CARBON
                            CREDITS.
                        </p>

                        <p>
                            <p> CARBC launch:</p>

                            <ul>
                                <li>Fair Launch;</li>

                                <li>No pre-sale;</li>

                                <li>No Pre-mine;</li>
                            </ul>
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                            Participants can only buy CARBC tokens at the start
                            of farming only.
                        </p>
                        <p>
                            <b>
                                The dev team will add CARBC-BUSD, CARBC-BNB
                                liquidities on Pancake Swap right at the farming
                                start.
                            </b>
                        </p>

                        <p>
                            <p> CARBC reward distribution:</p>

                            <ul>
                                <li>
                                    05.00% of the investor harvest goes to CARBC
                                    holders;
                                </li>

                                <li>
                                    05.00% of the investor harvest goes to the
                                    Donation Wallet, but at harvest the investor
                                    can choose if they want to make more
                                    donations (up to 100% of the rewards);
                                </li>

                                <li>Maximum supply cap is 21,000,000 CARBC;</li>
                                <li>
                                    The CARBC token has a transaction fee of 2%
                                    which goes to the Donation Wallet.
                                </li>
                            </ul>
                        </p>

                        <p>
                            <b>All CARBC pairs:</b>
                            Deposit fee: 0.10% - 100% to Mkt wallet.
                        </p>

                        <p>
                            <b>All external pairs:</b>
                            Buyback: 0.8% of fee will go to the CLIME buyback
                            and burn; <br />
                            Operational fee: 1.5% to platform operators;
                            <br />
                            Special reward pool: 0.7% to individual reward pools
                            (staking);
                            <br />
                            Development & marketing: 1% to dev wallet;
                            <br />
                            Total fee: 4% on profits
                        </p>

                        <p>
                            <b>About our Farm:</b>
                            The CARBC token will be available for farming and
                            staking after the CLIME token pre-sale ends.
                        </p>
                        <p style={{ fontStyle: "italic" }}>
                            Better rewards will be available for the CLIME token
                            holders.
                        </p>

                        <p>
                            <p>
                                {" "}
                                Benefits of generating, buying and holding CARBC
                                token:
                            </p>

                            <ul>
                                <li>
                                    In addition to the CARBC appreciation
                                    itself, investors will be able to stake and
                                    farm to generate more carbon credits (CARBC)
                                    for itself and for donation;
                                </li>

                                <li>
                                    CARBC will be the official currency for our
                                    future metaverse platform, whereusers with
                                    the most carbon credits will have more
                                    opportunities to buy better assets (forests,
                                    rivers, farms, etc.).
                                </li>
                            </ul>
                        </p>
                        <button>Buy CARBC</button>
                    </Col>
                </Row>
                <img src={require("../../assets/info.png")} className="infobtm wow fadeInUp"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.3s" alt="" />
            </Container>
        </Box>
    );
};
const Box = styled.section`
    min-height: 100vh;
    padding-bottom:0;
    .row {
        h5 {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 20px;
            display:flex;
            align-items:center;
            span{
                font-weight:500
            }
            img{
                margin-right:14px;
                height:50px;
                width:auto;
            }
            @media screen and (max-width:992px){
                font-size: 22px;
                img{
                    height:30px;
                }
              }
        }
        ul {
            padding-left: 16px;
            li {
                margin-bottom: 12px;
            }
        }
        .col-lg-6{
            // text-align: justify;
        }
    }
    .myrow2 {
        margin-top: 80px;
    }
    button {
        margin-top: 40px;
        display: block;
    }
    .infobtm{
        max-width:700px;
        width:100%;
        margin:auto;
        display:block;
        margin-top:80px
    }

    @media screen and (max-width:992px){
        .col-lg-6{
            margin-bottom:40px;
        }
        .myrow2 {
            margin-top: 0px;
        }
    }
`;

export default Info;
