import React from "react";
import { Container } from "react-bootstrap";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import styled from "styled-components";

const NavComp: React.FC = () => {
    return (
        <NavbarOuter bg="light" expand="lg"  className="wow fadeInDown"
        data-wow-duration="0.6s"
        data-wow-delay="0s">
            <Container>
                <Navbar.Brand href="#home"><img src={require("../../assets/logo.png")} className="logo" alt="" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav >
                        
                        <NavDropdown title="About">
                            <NavDropdown.Item smooth={true} href="#whitepaper">
                                Whitepaper
                            </NavDropdown.Item>
                            <NavDropdown.Item  smooth={true} href="#roadmap">
                                Roadmap
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Our Tokens">
                            <NavDropdown.Item smooth={true} href="#climetoken">
                            <img src={require("../../assets/clime.png")} alt="" /> CLIME
                            </NavDropdown.Item>
                            <NavDropdown.Item smooth={true} href="#carbctoken">
                            <img src={require("../../assets/carbc.png")} alt="" /> CARBC
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* <Nav.Link href="#">Climate Change Helpers</Nav.Link> */}
                        <Nav.Link href="https://www.cnbc.com/amp/2021/10/25/blackrock-ceo-larry-fink-next-1000-unicorns-will-be-in-climate-tech.html" target="_blank">News</Nav.Link>
                        <Nav.Link href="#">Exchange</Nav.Link>
                        <Nav.Link href="#">Farm</Nav.Link>
                        <Nav.Link href="#"> <button className="bg-greenish text-white">
                           Donate
                        </button></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </NavbarOuter>
    );
};

const NavbarOuter = styled(Navbar)`
.logo{
    max-width:350px;
    @media screen and (max-width:500px){
        max-width:250px;
      }
}

.navbar-toggler{
    border-color:transparent !important;
    box-shadow:none !important;
    margin-left: auto;
}
.navbar-nav{
    margin-left:auto;
    align-items: center;
    @media screen and (max-width:768px){
        margin-left:0;
    }
}
.navbar-brand{
    display:flex;
    align-items:center;
}

@media (min-width:992px) and (max-width:1400px){
    .logo{
        max-width: 320px;
    }
    .nav-link{
        padding-right: 0px !important;
        padding-left: 20px !important;
        // white-space: nowrap;
        font-size:14px
    }
    .nav-link button{
        font-size:12px
    }
}


@media screen and (max-width:768px){
    .navbar-nav{
        margin:0 !important;
        margin-top: 24px !important;
    }
    .navbar-brand{
        padding-left:0 !important;
    }
}

@media screen and (max-width:992px){
    .collapse:not(.show) {
        display: none !important;
    }
    #basic-navbar-nav .navbar-nav {
        width:100%;
    }
    .nav-item{
        padding-left:0
    }
    .nav-link{
        padding-left:0 !important;
        padding-right:0 !important
    }
}
    #basic-navbar-nav{
        .navbar-nav{
            // margin-left:auto;
            align-items:center;
            .nav-link{
                color:#03242a;
                padding-right: 20px;
                padding-left: 20px;
                &:last-child{
                    padding-right:0;
                }
            }
            .dropdown-menu{
                border-radius:14px;
                padding:4px;
                .dropdown-item{
                    display:flex;
                    align-items:center;
                    border-radius:8px;
                    padding:6px 14px;
                    img{
                        width:24px;
                        margin-right:8px;
                    }
                    &:hover{
                        color:#fff;
                        background:#7fb11d
                    }
                }
            }
        }
    }
`

export default NavComp;
