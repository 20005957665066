import React from "react";
import NavComp from './components/Navbar'
import Hero from './components/Hero'
import Info from './components/Info'
import Whitepaper from './components/Whitepaper'
import Roadmap from './components/Roadmap'
import Donate from './components/Donate'
import Team from './components/Team'
import Footer from './components/Footer'
const Home: React.FC = () => {
    return (
        <>
            <NavComp/>
            <Hero/>
            <Info/>
            <Whitepaper/>
            <Roadmap/>
            <Donate/>
            <Team/>
            <Footer/>
        </>
    );
};

export default Home;
